@import "variables";

//Bootstrap Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
// Textpic and Textmedia

.textpic {
    figure {
        margin-bottom: 0;

        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }

    &-left,
    &-right {
        @include media-breakpoint-up(md) {
            .row {
                align-items: center;
            }

            .textpic-text > *:last-child {
                margin-bottom: 0;
            }
        }
    }

    .textpic-text {
        .iteration-wrapper {
            align-items: flex-end;

            .cycle {
                color: $primary;
                margin-right: 30px;
                font-size: 5.2rem;
                line-height: 3.6rem;

                @include media-breakpoint-up(lg) {
                    font-size: 6.2rem;
                    line-height: 4.6rem;
                }
            }
        }

        a {
            position: relative;
            display: inline;
            text-decoration: none;
            background-image: linear-gradient($primary,$primary);
            background-position: 0 100%;
            background-repeat: no-repeat;
            background-size: 100% 3px;

            &:hover {
                color: $primary;
            }
        }
    }

    .textpic-img {
        text-align: center;
    }

    &-above {
        .textpic-img {
            margin-bottom: 2.375rem;

            @include media-breakpoint-up(lg) {
                margin-bottom: 3.5rem;
            }
        }

        &.textpic-rounded {
            .textpic-img {
                margin-bottom: 0.75rem;

                @include media-breakpoint-up(lg) {
                    margin-bottom: 1.5rem;
                }
            }
        }
    }

    &-below {
        .textpic-img {
            margin-top: 2.375rem;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 3.5rem;
        }

        &.textpic-rounded {
            .textpic-img {
                margin-top: 0.75rem;
            }

            @include media-breakpoint-up(lg) {
                margin-top: 1.5rem;
            }
        }
    }

    &-left {
        .textpic-img {
            @include media-breakpoint-down(sm) {
                margin-bottom: 2.375rem;
            }
        }

        .textpic-text {
            @include media-breakpoint-up(md) {
                margin-left: percentage(1/12);
            }
            @include media-breakpoint-up(xl) {
                margin-left: percentage(2/12);
            }
        }
    }

    &-right {
        .textpic-img {
            @include media-breakpoint-down(sm) {
                margin-top: 2.375rem;
            }
        }

        .textpic-text {
            @include media-breakpoint-up(md) {
                margin-right: percentage(1/12);
            }
            @include media-breakpoint-up(xl) {
                margin-right: percentage(2/12);
            }
        }
    }
}

.textpic-rounded {
    .image {
        text-align: left;
    }

    .textpic-text {
        word-break: break-word;
    }
}

